import { Grid } from "@mui/material";
import React from "react";
import Title from "../components/Title";
import LastUpdate from "../components/LastUpdate";
import Report from "../components/Report";
import "./Home.css";
import { CurrentConditions } from "../api/ApiResponses";

interface HomeMobileProps {
  currentConditions: CurrentConditions;
}

const HomeMobile: React.FC<HomeMobileProps> = ({ currentConditions }) => {
  return (
    <>
      {/* Title (Header) */}
      <Grid
        item
        container
        xs={12}
        height="10vh"
        alignContent="center"
        justifyContent="center"
      >
        <Grid item>
          <Title />
        </Grid>
      </Grid>
      {/* Report (Body) */}
      <Grid container item height="85vh">
        {/* Spacing top */}
        <Grid item xs={12} height="45vh" />
        {/* Content */}
        <Grid item container height="25vh">
          <Grid item xs={1} />
          <Grid container item xs={10} alignItems={"center"}>
            <Report currentConditions={currentConditions} />
          </Grid>
          <Grid item xs={1} />
        </Grid>

        {/* Spacing bottom */}
        <Grid item xs={12} height="5vh" />
      </Grid>
      {/* Last Update (Footer) */}
      <Grid item xs={12} height="5vh">
        <LastUpdate timestamp={currentConditions.timestamp} />
      </Grid>
    </>
  );
};

export default HomeMobile;
