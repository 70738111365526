import React from "react";
import { Swell } from "../api/ApiResponses";
import { Grid } from "@mui/material";
import Compass from "./Compass";
import "./SwellCard.css";

interface SwellCardProps {
  swell: Swell;
}

const SwellCard: React.FC<SwellCardProps> = ({ swell }) => {
  return (
    <Grid container item>
      {/* Compass arrow */}
      <Grid item xs={12} sx={{ display: { xs: "block", sm: "none" } }}>
        <Compass direction={swell.direction} />
      </Grid>
      {/* Direction */}
      <Grid container item xs={12} alignItems="center" justifyContent="center">
        <Grid item sm={8}>
          <h2 className="SwellDirection">
            {swell.compass + " " + swell.direction}
          </h2>
        </Grid>
        <Grid
          item
          container
          alignItems="center"
          sm={4}
          sx={{ display: { xs: "none", sm: "block" } }}
        >
          <Grid item alignContent="start">
            <Compass direction={swell.direction} />
          </Grid>
        </Grid>
      </Grid>

      {/* Height and period */}
      <Grid item xs={12}>
        <h4 className="SwellHeightPeriod">
          {formatHeightAndPeriod(swell.height, swell.period)}
        </h4>
      </Grid>
    </Grid>
  );
};

function formatHeightAndPeriod(height: number, period: number): string {
  const formattedFeet = height.toFixed(1);
  const formattedSeconds = period.toFixed(1);

  return `${formattedFeet}FT ${formattedSeconds}S`;
}

export default SwellCard;
