import React from "react";
import { CurrentConditions } from "../api/ApiResponses";
import { Container, Grid } from "@mui/material";
import WaveHeight from "./WaveHeight";
import Tide from "./Tide";
import Swells from "./Swells";
import "./Report.css";

interface ReportProps {
  currentConditions: CurrentConditions;
}

const Report: React.FC<ReportProps> = ({ currentConditions }) => {
  return (
    <Grid container item height="100%" alignItems="center">
      {/* Mobile */}
      <Grid
        container
        item
        className="ReportBox"
        padding={2}
        height="100%"
        alignContent="space-around"
        sx={{ display: { xs: "flex", sm: "none" } }}
      >
        {/* Wave Height */}
        <Grid item xs={6}>
          <WaveHeight height={currentConditions.waveHeight} />
        </Grid>
        {/* Tide */}
        <Grid item xs={6}>
          <Tide
            height={currentConditions.tide.height}
            isDropping={currentConditions.tide.isDropping}
          />
        </Grid>
        {/* Swells */}
        <Grid item container xs={12}>
          <Swells swells={currentConditions.swells} />
        </Grid>
      </Grid>
      {/* Desktop */}
      <Grid
        container
        item
        sx={{ display: { xs: "none", sm: "flex" } }}
        height="100%"
        alignItems="center"
      >
        {/* Wave Height */}
        <Grid item container className="ReportBox" padding={5}>
          <Grid item xs={6}>
            <WaveHeight height={currentConditions.waveHeight} />
          </Grid>
          {/* Tide */}
          <Grid item xs={6}>
            <Tide
              height={currentConditions.tide.height}
              isDropping={currentConditions.tide.isDropping}
            />
          </Grid>
        </Grid>
        {/* Swells */}
        <Grid item container xs={12}>
          <Swells swells={currentConditions.swells} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Report;
