import "./App.css";
import Home from "./views/Home";
import { QueryClient, QueryClientProvider } from "react-query";
import Background from "./components/Background";

function App() {
  const queryClient = new QueryClient();
  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <Background>
          <Home />
        </Background>
      </QueryClientProvider>
    </div>
  );
}

export default App;
