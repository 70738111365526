import { Grid } from "@mui/material";
import React from "react";
import "./Tide.css";

interface Props {
  height: number;
  isDropping: boolean;
}

const Tide: React.FC<Props> = ({ height, isDropping }) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <h2 className="TideHeight">{formatTideHeight(height)}</h2>
      </Grid>
      <Grid item xs={12}>
        <h3 className="RisingDropping">
          {isDropping ? "DROPPING TIDE" : "RISING TIDE"}
        </h3>
      </Grid>
    </Grid>
  );
};

function formatTideHeight(height: number): string {
  const formattedFeet = height.toFixed(1);

  return `${formattedFeet} FT`;
}

export default Tide;
