import React from "react";
import { Swell } from "../api/ApiResponses";
import { Grid } from "@mui/material";
import SwellCard from "./SwellCard";

interface Props {
  swells: Swell[];
}

const Swells: React.FC<Props> = ({ swells }) => {
  if (swells.length === 0) {
    return null;
  }

  if (swells.length >= 3) {
    return (
      <>
        <Grid item xs={4}>
          <SwellCard swell={swells[0]} />
        </Grid>
        <Grid item xs={4}>
          <SwellCard swell={swells[1]} />
        </Grid>
        <Grid item xs={4}>
          <SwellCard swell={swells[2]} />
        </Grid>
      </>
    );
  } else if (swells.length === 2) {
    return (
      <>
        <Grid item xs={6}>
          <SwellCard swell={swells[0]} />
        </Grid>
        <Grid item xs={6}>
          <SwellCard swell={swells[1]} />
        </Grid>
      </>
    );
  } else {
    return (
      <Grid item xs={12}>
        <SwellCard swell={swells[0]} />
      </Grid>
    );
  }
};

export default Swells;
