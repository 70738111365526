import React, { ReactNode } from "react";
import background from "../images/mark.jpg";

interface BackgroundProps {
  children: ReactNode;
}

const Background: React.FC<BackgroundProps> = ({ children }) => {
  const backgroundImageUrl = `url(${background})`;

  return (
    <div
      style={{
        backgroundImage: backgroundImageUrl,
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "100vh",
      }}
    >
      {children}
    </div>
  );
};

export default Background;
