import React from "react";
import "./Title.css";

interface TitleProps {}

const Title: React.FC<TitleProps> = () => {
  return <h1 className="Title">SECRET SURF SPOT</h1>;
};

export default Title;
