import React from "react";
import NavigationIcon from "@mui/icons-material/Navigation";
import "./Compass.css";

interface CompassProps {
  direction: number;
}

const Compass: React.FC<CompassProps> = ({ direction }) => {
  return (
    <div style={{ transform: `rotate(${direction + 180}deg)` }}>
      <NavigationIcon
        style={{ fontSize: "5vw" }}
        htmlColor="white"
        className="NavigationIcon"
      />
    </div>
  );
};

export default Compass;
