import { Grid } from "@mui/material";
import React from "react";
import Title from "../components/Title";
import { useQuery } from "react-query";
import { getCurrentConditions } from "../api/ApiHelper";
import "./Home.css";
import HomeMobile from "./HomeMobile";
import HomeDesktop from "./HomeDesktop";

interface HomeProps {}

const Home: React.FC<HomeProps> = () => {
  const { data: currentConditions } = useQuery(
    "currentConditions",
    getCurrentConditions
  );

  if (!currentConditions) {
    return (
      <Grid container height="100vh">
        {/* Title */}
        <Grid item xs={12}>
          <Title />
        </Grid>
        <Grid item xs={12}>
          <h2 className="Loading">LOADING...</h2>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container>
      {/* Mobile */}
      <Grid
        item
        container
        height="100vh"
        sx={{ display: { xs: "flex", sm: "none" } }}
      >
        <HomeMobile currentConditions={currentConditions} />
      </Grid>
      {/* Desktop */}
      <Grid item container sx={{ display: { xs: "none", sm: "flex" } }}>
        <HomeDesktop currentConditions={currentConditions} />
      </Grid>
    </Grid>
  );
};

export default Home;
