import { Grid } from "@mui/material";
import React from "react";
import "./WaveHeight.css";

enum RelativeHeight {
  Unknown = "",
  Flat = "FLAT",
  Knee = "KNEE HIGH",
  Waist = "WAIST HIGH",
  Shoulder = "SHOULDER HIGH",
  Head = "HEAD HIGH",
  Overhead = "OVERHEAD",
}

interface WaveReport {
  RelativeHeight: RelativeHeight;
  Range: string;
}

interface Props {
  height: number;
}

const WaveHeight: React.FC<Props> = ({ height }) => {
  let waveReport = getWaveReport(height);
  return (
    <Grid container>
      <Grid item xs={12}>
        <h2 className="WaveHeight">{waveReport.Range}</h2>
      </Grid>
      <Grid item xs={12}>
        <h3 className="WaveHeightRelative">{waveReport.RelativeHeight}</h3>
      </Grid>
    </Grid>
  );
};

function getWaveReport(waveHeight: number | undefined): WaveReport {
  if (waveHeight === undefined) {
    return {
      RelativeHeight: RelativeHeight.Unknown,
      Range: "Failed to fetch wave height",
    };
  }

  if (waveHeight < 1) {
    return { RelativeHeight: RelativeHeight.Flat, Range: "0-1 FT" };
  } else if (waveHeight >= 1 && waveHeight < 2) {
    return { RelativeHeight: RelativeHeight.Knee, Range: "1-2 FT" };
  } else if (waveHeight >= 2 && waveHeight < 4) {
    return { RelativeHeight: RelativeHeight.Waist, Range: "2-4 FT" };
  } else if (waveHeight >= 4 && waveHeight < 5) {
    return { RelativeHeight: RelativeHeight.Shoulder, Range: "4-5 FT" };
  } else if (waveHeight >= 5 && waveHeight < 6) {
    return { RelativeHeight: RelativeHeight.Head, Range: "5-6 FT" };
  } else {
    return { RelativeHeight: RelativeHeight.Overhead, Range: "6+ FT" };
  }
}

export default WaveHeight;
