import React from "react";
import "./LastUpdate.css";

interface Props {
  timestamp: string;
}

const LastUpdate: React.FC<Props> = ({ timestamp }) => {
  const parsedTimeStamp = Date.parse(timestamp);
  const date = new Date(parsedTimeStamp);
  return (
    <h4 className="LasUpdateText">
      LAST UPDATED:{" "}
      {date.toLocaleTimeString([], {
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
      })}
    </h4>
  );
};

export default LastUpdate;
